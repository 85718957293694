<template>
  <el-dialog
    title="修改缴费状态"
    :visible.sync="openDialog"
    width="500px"
    :show-close="true"
    class="dialog-vertical"
    @close="cancel('statusForm')"
  >
    <el-form
      size="medium"
      :model="statusForm"
      :rules="rules"
      ref="statusForm"
      label-width="80px"
    >
      <el-form-item
        style="margin-bottom: 0px"
        label="缴费状态："
        prop="pay_status"
      >
        <el-select
          v-model="statusForm.pay_status"
          placeholder="请选择"
          style="width: 95%"
          :disabled="
            statusForm.pay_status === 2 && statusForm.position_fee === 0
          "
        >
          <el-option
            v-for="item in PayStatusArr"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <template v-if="target === 'position'">
          <div
            class="form-tip"
            v-if="statusForm.pay_status === 2 && statusForm.position_fee === 0"
          >
            <span
              class="el-icon-info"
              style="font-size: 15px; color: #c0c4cc"
            ></span>
            <span>该职务的会费状态为无需缴费，无法进行修改</span>
          </div>
        </template>
      </el-form-item>
      <!-- 已缴费时可填缴费时间 -->
      <template v-if="true || target === 'user'">
        <el-form-item
          v-if="statusForm.pay_status === 1"
          style="margin-top: 20px"
          label="缴费时间："
          prop="pay_time"
        >
          <el-date-picker
            v-model="statusForm.pay_time"
            style="width: 95%"
            type="datetime"
            value-format="timestamp"
            placeholder="请选择"
          >
          </el-date-picker>
        </el-form-item>
      </template>
    </el-form>
    <slot><!-- 这里放置批量操作结果 --></slot>
    <span slot="footer">
      <template v-if="!isComplete">
        <el-button @click="openDialog = false">取消</el-button>
        <el-button
          type="primary"
          @click="submit('statusForm')"
          :loading="saveLoading"
          >确定</el-button
        >
      </template>
      <template v-else>
        <el-button @click="openDialog = false">关闭</el-button>
      </template>
    </span>
  </el-dialog>
</template>

<script>
import { ChangePayStatus } from '../../api/member/member-detail'
export default {
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    PayForm: {
      type: Object,
    },
    updateList: {
      type: Function,
    },
    onSubmit: Function,
    // 修改的目标是人还是职务，默认是职务
    target: {
      type: String,
      default: 'position',
    }, // user | position
    isComplete: Boolean, // 批量操作完成
  },
  data() {
    const timeValidator = (rule, value, callback) => {
      if (
          this.statusForm.pay_time === null ||
          this.statusForm.pay_time === '' ||
          this.statusForm.pay_time === 0
      ) {
        callback(new Error('请选择缴费时间'))
      } else {
        callback()
      }
    }
    return {
      statusForm: {
        id: '',
        pay_status: '',
      },
      PayStatusArr: [
        { name: '未缴费', id: 0 },
        { name: '已缴费', id: 1 },
        { name: '无需缴费', id: 2 },
      ],
      rules: {
        pay_status: [
          { required: true, message: '请选择缴费状态', trigger: 'change' },
        ],
        pay_time: [
          { required: true, message: '请选择缴费时间', trigger: 'change' },
          { validator: timeValidator, trigger: 'change' },
        ],
      },
      saveLoading: false,
    }
  },
  watch: {
  //   openDialog(val) {
  //     if (val) {
  //       this.PayStatusArr =
  //         this.PayForm.pay_status !== 2
  //           ? [
  //               { name: "未缴费", id: 0 },
  //               { name: "已缴费", id: 1 },
  //             ]
  //           : [{ name: "无需缴费", id: 2 }];
  //     }
  //   },
    'statusForm.pay_status' (val) {
      if (val === 1) {
        if (this.statusForm.pay_time > 0) {
          // 忽略
        } else {
          // 自动选择当前日期时间
          this.statusForm.pay_time = new Date().getTime() // 毫秒
        }
      }
    }
  },
  methods: {
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.onSubmit !== undefined) {
            this.onSubmit(this.statusForm)
            return
          }
          const data = {
            ...this.statusForm,
            pay_time:
              this.statusForm.pay_status === 1 ? (this.statusForm.pay_time > 0
                ? this.statusForm.pay_time / 1000
                : 0) : 0, // 不是已缴费就默认传 0
          }
          this.saveLoading = true
          ChangePayStatus(data)
            .then((res) => {
              this.updateList()
              this.saveLoading = false
              this.openDialog = false
            })
            .catch((err) => {
              this.saveLoading = false
            })
        } else {
          return false
        }
      })
    },
    // 取消
    cancel(formName) {
      this.openDialog = false
    },
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.$nextTick(() => {
          this.statusForm = {
            ...this.PayForm,
          }
          this.statusForm.pay_status = [0, 1, 2].includes(
            this.statusForm.pay_status
          )
            ? this.statusForm.pay_status
            : ''

          console.log(this.statusForm)
        })
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
    // statusForm: {
    //   get() {
    //     const formData = {
    //       ...this.PayForm,
    //     };
    //     console.log("formData", formData);
    //     // formData.pay_status =
    //     //   formData.pay_status === 3 ? "" : formData.pay_status;
    //     return formData;
    //   },
    //   set(val) {
    //     this.$emit("update: form", val);
    //   },
    // },
  },
}
</script>
<style lang="scss" scoped>
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 6px;
  span + span {
    margin-left: 4px;
  }
}
</style>
