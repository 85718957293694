<!--
 * @Author: dongjia
 * @Date: 2021-10-19 15:44:55
 * @LastEditTime: 2021-10-19 18:18:19
 * @LastEditors: aleaner
 * @Description: 会员在该企业职务填写弹窗
 * @FilePath: \organization-admin\components\Member\InputCompanyPositionDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="填写企业职务" :visible.sync="show" width="500px" class="dialog-vertical" @close="onClose"
    @open="onOpen" append-to-body>
    <div class="content">
      <el-form size="medium" :model="_data" :rules="rules" ref="companyPositon" label-width="100px">
        <el-form-item label="在该企业职务" prop="company_position">
          <el-input v-model="company_position" placeholder="请填写在该企业职务名称"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button size="medium" @click="show=false">取消</el-button>
      <el-button type="primary" size="medium" :loading="confirmLoading" @click="onConfirm('companyPositon')">
        确定
      </el-button>
    </template>
  </el-dialog>
</template>
 
<script>
import { assocUser } from "../../api/member/member-detail";
export default {
  model: {
    prop: "open",
    event: "handleOpen",
  },
  props: {
    //是否打开
    open: {
      type: Boolean,
      required: true,
    },
    // 关联企业参数数据
    relateData: {
      type: Object,
      required: true,
    },
    //完成后的回调
    complete: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      confirmLoading: false, //确认loading
      company_position: "", // 企业职务
      rules: {
        company_position: [
          { required: true, message: "请填写在该企业职务名称" },
        ],
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("handleOpen", val);
      },
    },
  },
  methods: {
    //弹窗打开
    onOpen() {},
    //弹窗关闭
    onClose() {
      this.show = false;
    },
    //确认事件
    onConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          assocUser({
            ...this.relateData,
            company_position: this.company_position,
          })
            .then((res) => {
              this.$message.success(res.msg);
              this.complete();
              this.$bus.emit("getRelateCompanyResult", res.data);
              this.saveLoading = false;
              this.show = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
 
<style lang="scss" scoped>
</style>