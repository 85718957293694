<template>
  <el-dialog :title="CheckType ? '审核通过' : '审核不通过'" :visible.sync="openDialog" width="441px" :show-close="true"
    class="dialog-vertical" @close="cancel('CheckForm')">
    <el-form size="medium" :model="CheckForm" :rules="rules" ref="CheckForm"
      :label-width="CheckType ? '70px' : '110px'" label-position="top">
      <el-form-item style="margin-bottom: 24px" label-width="0px" v-if="SelectShow">
        <p style="margin-bottom: 10px">
          {{
            `该会员申请了 ${PositionList.length} 个组织，请选择${
              CheckType ? "要通过" : "不通过"
            }的组织：`
          }}
        </p>
        <el-checkbox-group class="check-post" v-model="CheckForm.id">
          <el-checkbox style="margin-right: 40px" v-for="(item, index) in PositionList"
            :label="item.user_position_id" :key="index">{{ item.join_organization_name }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item style="margin-bottom: 24px" :label="CheckType ? '意见详情' : '不通过原因'" prop="check_feedback">
        <el-input type="textarea" v-model="CheckForm.check_feedback" :autosize="{ minRows: 4 }"
          style="width: 100%" maxlength="20" show-word-limit
          :placeholder="`请填写${CheckType ? '意见详情' : '不通过原因'}`"></el-input>
      </el-form-item>
      <!-- <el-form-item label="备注" prop="check_remark">
        <el-input type="textarea" v-model="CheckForm.check_remark" :autosize="{ minRows: 4 }"
          style="width: 100%" maxlength="20" show-word-limit placeholder="请填写备注"></el-input>
      </el-form-item> -->
    </el-form>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit('CheckForm')" :loading="saveLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Checked, NoCheck } from "../../api/member/member-check";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    IDs: {
      type: Array,
    },
    PositionList: {
      type: [Array, Object],
      default: () => [],
    },
    CheckType: {
      type: Number,
    },
    SelectShow: {
      type: Boolean,
      default: false,
    },
    updateList: {
      type: Function,
    },
    pageset: String,
  },
  data() {
    return {
      CheckForm: {
        id: 0,
        check_feedback: "", // 意见/不通过原因
        check_remark: "", // 备注
      },
      rules: {
        check_feedback: [
          { required: true, message: "请填写审核意见", trigger: "blur" },
        ],
        // check_remark: [
        //   { required: true, message: "请填写备注", trigger: "blur" },
        // ],
      },
      PositionCheck: "",
      saveLoading: false,
    };
  },
  methods: {
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          const data = { ...this.CheckForm, pageset: this.pageset };
          if (this.CheckType) {
            Checked(data)
              .then((res) => {
                this.updateList();
                this.$refs[formName].resetFields();
                this.$message.success("审核通过");
                this.saveLoading = false;
                this.openDialog = false;
              })
              .catch((err) => {
                this.saveLoading = false;
              });
          } else {
            NoCheck(data)
              .then((res) => {
                this.updateList();
                this.$refs[formName].resetFields();
                this.$message.success("审核不通过");
                this.saveLoading = false;
                this.openDialog = false;
              })
              .catch((err) => {
                this.saveLoading = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.$refs[formName].resetFields();
    },
  },
  watch: {
    IDs(val) {
      this.CheckForm.id = val;
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.check-post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.dialog-vertical {
  ::v-deep.el-form-item__label {
    padding-bottom: 0px;
  }
}
</style>
