<template>
  <div class="confirm-content">
    <el-table :data="positions" class="thead-light" stripe style="width: 100%">
      <!-- 序号 -->
      <el-table-column width="60" type="index" label="序号"></el-table-column>
      <!-- 操作 -->
      <el-table-column label="操作" :width="routeName === 'AddMember' ? '120' : '170'" fixed="right">
        <template slot-scope="scope">
          <div v-if="routeName !== 'AddMember'">
            <el-button-group v-if="scope.row.status === 1">
              <el-button type="text" size="small" @click="changeRow(scope.row, 2)">修改</el-button>
              <el-button type="text" size="small" @click="changeRow(scope.row, 1)">晋升</el-button>
              <el-divider direction="vertical" />
              <el-dropdown @command="handleClickRow($event, scope.row)">
                <span style="color: #3576ff; font-size: 12px" class="el-dropdown-link">更多</span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="PayStatusRow">会费状态</el-dropdown-item>
                  <el-dropdown-item v-for="(item, index) in moreHandleArr" :key="index" :command="item.command">{{
                    item.name
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-button-group>
            <el-button-group v-if="scope.row.status === 2">
              <el-button type="text" size="small" @click="changeRow(scope.row, 2)">修改</el-button>
              <el-button type="text" size="small" @click="changeRow(scope.row, 1)">晋升</el-button>
              <el-divider direction="vertical" />
              <el-dropdown @command="handleClickRow($event, scope.row)">
                <span style="color: #3576ff; font-size: 12px" class="el-dropdown-link">更多</span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(item, index) in moreHandleArr" :key="index" :command="item.command">{{
                    item.name
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-button-group>
            <el-button-group v-if="type === 'register'">
              <el-button type="text" size="small" @click="PassRow(scope.row, 1)">通过</el-button>
              <el-button type="text" size="small" @click="PassRow(scope.row, 0)">不通过</el-button>
            </el-button-group>
          </div>
          <el-button-group v-else>
            <el-button type="text" size="small" @click="EditRow(scope.row, scope.$index)">编辑</el-button>
            <el-button type="text" size="small" @click="DelRow(scope.row, scope.$index)">移除</el-button>
          </el-button-group>
        </template>
      </el-table-column>
      <template v-for="(th, index) in thead[type]">
        <el-table-column
          show-overflow-tooltip
          :prop="th.prop"
          :label="th.label"
          :min-width="th.minWidth || ''"
          :sortable="th.sortable"
          :key="index"
        >
          <template slot-scope="scope">
            <!-- 会员类型 -->
            <span v-if="th.prop === 'status'">{{ scope.row[th.prop] === 1 ? "正式会员" : "预备会员" }}</span>
            <!-- 入会时间 -->
            <span v-else-if="th.prop === 'join_time'">
              <span>{{scope.row[th.prop] | placeholder}}</span>
              <el-link style="margin-left:10px" type="primary" :underline="false" @click="openChangeJoinTimeDialog(scope.row)">修改</el-link>
            </span>
            <!-- 缴费时间 -->
            <span v-else-if="th.prop === 'pay_time_text'">
              <span>{{scope.row[th.prop] | placeholder}}</span>
              <el-link style="margin-left:10px" type="primary" :underline="false" @click="PayStatusRow(scope.row)">修改</el-link>
            </span>
            <!-- 设为展示 -->
            <el-radio v-else-if="th.prop === 'on_show'" v-model="OnShow" :label="scope.$index + 1" @change="handleSelect"
              >{{ " " }}
            </el-radio>
            <el-checkbox v-else-if="th.prop === 'show_on_details'" v-model="scope.row.show_on_details" :true-label="1" :false-label="0" @change="handleDetailSelect($event, scope.$index)"
              >{{ " " }}
            </el-checkbox>
            <!-- 普通 -->
            <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <add-button v-if="type !== 'register'" :height="36" @click="AddPosition" style="margin-top: 20px;">+ 添加职务 </add-button>
    <ChangePayStatus
      v-model="isOpenChangePayStatus"
      :PayForm="StatusPayForm"
      :updateList="updateList"
      :form.sync="StatusPayForm"
    />
    <ReasonDialog
      v-model="openReason"
      :IDs="Ids"
      :CheckType="current_check_type"
      :SelectShow="isShowSelect"
      :updateList="updateList"
    />
    <add-position
      v-model="isOpenAddPosition"
      :isJoined="isJoined"
      :updateList="update"
      :formData="postForm"
      @change="addPosition"
    >
    </add-position>
    <change-position
      v-model="isOpenChangePosition"
      :handleType="handleType"
      :isJoined="isJoined"
      :updateList="update"
      :formData="postForm"
      ref="cp1"
    ></change-position>
     <!-- 修改入会时间弹窗 -->
     <ChangeJoinTime :openDialog="showChangeJoinTime" :user_position_id="currentId" @hideDialog="closeChangeJoinTimeDialog" />
  </div>
</template>

<script>
import ChangePayStatus from "./ChangePayStatus.vue";
import ReasonDialog from "./ReasonDialog";
import ChangePosition from "./ChangePosition.vue";
import { ExitOrganization } from "../../api/member/member-list";
import { onShow } from "../../api/member/member-detail";
import AddPosition from "./AddPosition.vue";
import AddButton from '@/base/components/Base/AddButton'
import {toggleShowDetails} from "@/modules/organization-admin/api/member/member-detail";
import ChangeJoinTime from './ChangeJoinTime'
export default {
  props: {
    type: {
      type: String,
    },
    UserId: {
      type: [Number, String],
    },
    ID: {
      type: [Number, String],
    },
    socialText: {
      type: String,
      default: "",
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      // 职务信息
      PositionForm: {
        id: 0, // 新增的则为 0
        join_organization_id: 0, //加入时选的招收架构
        join_organization_name: "",
        organization_id: 0, //职务对应的 所属架构 ID
        organization_name: "",
        position_id: 0, //职务ID
        position_name: "",
        on_show: 0, //小程序端展示的信息
        pay_status: 0, //支付状态 缴费状态（0：未缴费，1：已缴费，2：无需缴费）
        referee: "", //推荐人
        status: 0, // 1:正式会员，2:预备会员
        first_organization_id: 0,
        title_name: "",
      },
      //架构职务信息
      postForm: {
        cyc_user_id: this.ID,
        user_id: this.UserId,
        id: 0,
        organization_name: "",
        title_name: "",
        join_organization_id: "",
        position_id: "",
        position_name: "",
        pay_status: 0, // 缴费状态
        referee: "",
      },
      // 缴费状态
      StatusPayForm: {
        id: 0,
        pay_status: 0,
        position_fee: 0,
      },
      thead: {
        formal: [
          { label: "会内职务", prop: "title_name", minWidth: 150 },
          { label: "会费状态", prop: "pay_status_text", minWidth: 100 },
          { label: "申请架构", prop: "apply_organization_name", minWidth: 150 },
          { label: "申请职务", prop: "apply_position_name", minWidth: 110 },
          { label: "会员类型", prop: "status", minWidth: 100 },
          { label: "入会时间", prop: "join_time", minWidth: 220 },
          { label: "缴费时间", prop: "pay_time_text", minWidth: 220 },
          { label: "推荐人", prop: "referee", minWidth: 110, maxLength: 32 },
          // { label: "社会身份", prop: "social_role_text", minWidth: 110 },
          { label: "设为展示", prop: "on_show", minWidth: 100 },
          { label: '会员主页、电子名片显示', prop: 'show_on_details', minWidth: 180},
        ],
        normal: [
          { label: "会内职务", prop: "title_name", minWidth: 150 },
          { label: "会费状态", prop: "pay_status_text", minWidth: 100 },
          { label: "缴费时间", prop: "pay_time_text", minWidth: 120 },
          { label: "申请架构", prop: "apply_organization_name", minWidth: 150 },
          { label: "申请职务", prop: "apply_position_name", minWidth: 110 },
          { label: "会员类型", prop: "status", minWidth: 100 },
          { label: "推荐人", prop: "referee", minWidth: 110, maxLength: 32 },
          // { label: "社会身份", prop: "social_role_text", minWidth: 110 },
          { label: "设为展示", prop: "on_show", minWidth: 100 },
          { label: '会员主页、电子名片显示', prop: 'show_on_details', minWidth: 180},
        ],
        pre: [
          { label: "会内职务", prop: "title_name", minWidth: 150 },
          { label: "会费状态", prop: "pay_status_text", minWidth: 100 },
          { label: "缴费时间", prop: "pay_time_text", minWidth: 120 },
          { label: "申请架构", prop: "apply_organization_name", minWidth: 150 },
          { label: "申请职务", prop: "apply_position_name", minWidth: 110 },
          { label: "会员类型", prop: "status", minWidth: 100 },
          { label: "推荐人", prop: "referee", minWidth: 110, maxLength: 32 },
          // { label: "社会身份", prop: "social_role_text", minWidth: 110 },
          { label: "设为展示", prop: "on_show", minWidth: 100 },
          { label: '会员主页、电子名片显示', prop: 'show_on_details', minWidth: 180},
        ],
        register: [
          { label: "会内职务", prop: "title_name", minWidth: 150 },
          { label: "申请职务", prop: "apply_position_name", minWidth: 110 },
          { label: "会员类型", prop: "status", minWidth: 100 },
          { label: "推荐人", prop: "referee", minWidth: 110, maxLength: 32 },
          // { label: "社会身份", prop: "social_role_text", minWidth: 110 },
          { label: '会员主页、电子名片显示', prop: 'show_on_details', minWidth: 180},
        ],
        checking: [
          { label: "会内职务", prop: "title_name", minWidth: 150 },
          { label: "申请职务", prop: "apply_position_name", minWidth: 110 },
          { label: "会员类型", prop: "status", minWidth: 100 },
          { label: "推荐人", prop: "referee", minWidth: 110, maxLength: 32 },
          // { label: "社会身份", prop: "social_role_text", minWidth: 110 },
          { label: '会员主页、电子名片显示', prop: 'show_on_details', minWidth: 180},
        ],
        add: [
          { label: "会内职务", prop: "title_name", minWidth: 150 },
          { label: "会费状态", prop: "pay_status_text", minWidth: 100 },
          { label: "申请架构", prop: "organization_name", minWidth: 150 },
          { label: "申请职务", prop: "position_name", minWidth: 110 },
          { label: "会员类型", prop: "status", minWidth: 100 },
          // { label: "社会身份", prop: "social_role_text", minWidth: 110 },
          { label: "推荐人", prop: "referee", minWidth: 110, maxLength: 32 },
          { label: "设为展示", prop: "on_show", minWidth: 100 },
          { label: '会员主页、电子名片显示', prop: 'show_on_details', minWidth: 180},
        ],
      },
      moreHandleArr: [
        { name: "申请信息", command: "changeRow" },
        { name: "降职", command: "Demotion" },
        { name: "退会", command: "Exit" },
      ],
      routeName: this.$route.name,
      isJoined: [], // 已申请加入的架构
      Ids: [],
      OnShow: 0, // 设为展示的职务
      onDetailShow: 0,
      currentId: 0,
      currentOrganizationId: 0,
      handleType: 0,
      currentIndex: -1,
      current_check_type: 0,
      isShowSelect: false,
      isOpenChangePosition: false,
      isOpenAddPosition: false,
      isOpenChangePayStatus: false,
      openReason: false, // 申请审核弹窗
      showChangeJoinTime:false
    };
  },
  methods: {
    // 打开入会时间弹窗
    openChangeJoinTimeDialog(row){
      this.currentId = row.id
      this.showChangeJoinTime = true
    },
    // 关闭入会时间弹窗
    closeChangeJoinTimeDialog(){
      this.showChangeJoinTime = false
      this.update()
    },
    // 点击添加
    AddPosition() {
      let joinedArr = [];
      this.positions.forEach((element) => {
        joinedArr.push(element.position_id);
      });
      this.isJoined = joinedArr;
      this.currentIndex = -1;
      this.postForm = {
        cyc_user_id: this.ID,
        user_id: this.UserId,
        id: 0,
        organization_name: "",
        position_name: "",
        title_name: "",
        join_organization_id: "",
        position_id: "",
        pay_status: 0, // 缴费状态
        referee: "",
        show_on_details: 1
      };
      this.isOpenAddPosition = true;
    },
    handleDetailSelect(e, index) {
      toggleShowDetails({
        id: this.positions[index].id,
        show_on_details: e
      }).then(res => {
        this.$message.success(res.msg)
      }).catch(() => {
      })
    },
    // 设置展示职务
    handleSelect(e) {
      if (this.type !== "add") {
        const id = this.positions[e - 1].id;
        this.$emit("update:loading", true);
        onShow({ id })
          .then((res) => {
            this.$message.success(res.msg);
            this.updateList();
          })
          .catch((err) => {});
      } else {
        const beforeData = this.positions.find((item) => item.on_show === 1);
        const afterData = this.positions.find((item, i) => i === e - 1);
        beforeData.on_show = 0;
        afterData.on_show = 1;
      }
    },
    // 点击更多
    handleClickRow(val, row) {
      switch (val) {
        case "changeRow":
          if (row.status === 1) {
            this.changeRow(row, 5);
          } else if (row.status === 2) {
            this.changeRow(row, 4);
          }
          break;
        case "Demotion":
          this.changeRow(row, 3);
          break;
        default:
          this[val](row);
      }
    },
    // 点击修改/晋升/降职/申请信息
    changeRow(row, handleType) {
      this.$refs.cp1.updateIds(row.path_ids)
      let joinedArr = [];
      this.positions.forEach((element) => {
        joinedArr.push(element.position_id);
      });
      this.isJoined = joinedArr;
      this.handleType = handleType;
      this.postForm = row;
      this.isOpenChangePosition = true;
    },
    // 点击编辑
    EditRow(row, i) {
      let joinedArr = [];
      this.positions.forEach((element) => {
        if (row.position_id !== element.position_id) {
          joinedArr.push(element.position_id);
        }
      });
      this.isJoined = joinedArr;
      this.postForm = row;
      this.currentIndex = i;
      this.isOpenAddPosition = true;
    },
    // 点击移除
    DelRow(row, i) {
      const delData = this.positions.splice(i, 1);
      if (delData.on_show) {
        this.positions[0].on_show = 1;
      }
    },
    //点击晋升
    UpgradeRow(row) {
      let joinedArr = [];
      this.positions.forEach((element) => {
        joinedArr.push(element.position_id);
      });
      this.isJoined = joinedArr.filter((item) => item !== row.organization_id);
      this.currentId = row.id;
      this.isOpenUpgradPosition = true;
    },
    // 修改缴费状态
    PayStatusRow(row) {
      this.StatusPayForm.id = row.id;
      this.StatusPayForm.pay_status = row.pay_status;
      this.StatusPayForm.pay_time = row.pay_time ? (row.pay_time * 1000) : '';
      this.StatusPayForm.position_fee = row.position_fee;
      this.isOpenChangePayStatus = true;
    },
    // 退会
    Exit(row) {
      let is_del_user_data = false
      this.$msgbox
        .confirm(
          (this.positions.length === 1 ?
            "确定将该会员退会吗？请谨慎操作" :
            "确定删除当前的会内职务吗？") + '<br/>' +
          '<input type="checkbox" style="vertical-align: middle;" id="is_del_user_data_position_list" />' +
          '删除产生的评论、内容发布等数据', "提示", {
            type: "error",
            dangerouslyUseHTMLString: true,
            beforeClose: (action, instance, done) => {
              is_del_user_data = document.getElementById('is_del_user_data_position_list')?.checked || false
              if (action === 'confirm') {
                const loading = this.$loading({
                  lock: true,
                  text: '正在处理退会……'
                });
                let id = row.id;
                ExitOrganization({ id, is_del_user_data : is_del_user_data ? 1 : 0 })
                  .then(() => {
                    loading.close()
                    this.$message.success("退会成功");
                    this.updateList();
                  })
                  .catch(() => {
                    loading.close()
                  });
              }
              done()
            }
          })
    },
    // 点击通过/不通过
    PassRow(row, status) {
      this.Ids = [row.id];
      this.isShowSelect = false;
      this.current_check_type = status;
      this.openReason = true;
    },
    // 新增会员时，添加职务
    addPosition(val) {
      if (this.currentIndex === -1) {
        this.positions.push({ ...val, on_show: this.positions.length ? 0 : 1 });
      } else {
        this.positions.splice(this.currentIndex, 1, { ...val, on_show: 0 });
      }
      this.$emit("change", val);
    },
    // 刷新职务列表
    update(value) {
      if (value) {
        const data = { ...value };
        if (this.currentIndex === -1) {
          data.on_show = this.positions.length ? 0 : 1;
          this.positions.push(data);
        } else {
          this.positions.splice(this.currentIndex, 1, data);
        }
        this.$store.commit("setPositionList", this.positions);
      } else {
        this.updateList();
      }
    },
  },
  watch: {
    positions: {
      deep: true,
      handler(val) {
        if (val.length) {
          const index = val.findIndex((item, i) => item.on_show === 1);
          if (index >= 0) {
            this.OnShow = index + 1;
          }
        }
      },
    },
  },
  computed: {
    positions() {
      return this.$store.getters.positionList;
    },
  },
  components: {
    AddButton,
    ChangePayStatus,
    ReasonDialog,
    ChangePosition,
    AddPosition,
    ChangeJoinTime
  },
};
</script>

<style lang="scss" scoped>
.tool-tip {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.el-button-group {
  display: flex;
  align-items: center;
}
</style>
