<template>
  <el-dialog
    :title="title"
    :visible.sync="openDialog"
    width="580px"
    :show-close="true"
    class="dialog-vertical"
    @close="cancel('transFormData')"
  >
    <el-form
      size="medium"
      :model="transFormData"
      :rules="rules"
      label-position="right"
      label-width="100px"
      ref="transFormData"
      v-loading="loading"
      @submit.native.prevent
    >
      <el-form-item :label="handleType === 4 ? '申请架构：' : '组织架构：'" prop="ids" v-if="handleType !== 5">
        <el-cascader
          style="width: 80%"
          v-model="transFormData.ids"
          :options="parentArr"
          :props="{ value: 'id', label: 'name', checkStrictly: true, expandTrigger: 'hover' }"
          placeholder="请选择组织架构"
          @change="handleSelect"
          :disabled="[1, 3].includes(handleType)"
        ></el-cascader>
        <div class="form-tip" v-if="[1, 3].includes(handleType)">
          <span class="el-icon-info" style="font-size: 15px; color: #c0c4cc"></span>
          <span>{{ `只能基于当前的组织架构下${handleType === 1 ? "晋升" : "降低"}职务` }}</span>
        </div>
      </el-form-item>
      <el-form-item :label="handleType === 4 ? '申请职务：' : '会内职务：'" prop="position_id" v-if="handleType !== 5">
        <el-select
          v-model="transFormData.position_id"
          :placeholder="positionPlaceholder ? positionPlaceholder : '请先选择组织架构'"
          style="width: 80%"
          :disabled="!transFormData.ids.length || loadingPositions"
          @change="positionSelect"
        >
          <el-option v-for="item in postList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="![4].includes(handleType) && payTip" label="会费差价：" prop="pay_status">
        <div>
          <el-radio v-model="transFormData.pay_status" :label="payStatus">需补差价</el-radio>
          <el-radio v-model="transFormData.pay_status" :label="-1">无需补差价</el-radio>
          <div class="form-tip" v-if="[0, 3].includes(payStatus)">
            <span class="el-icon-info" style="font-size: 15px; color: #c0c4cc"></span>
            <span>{{ payTip }}</span>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="推荐人：" prop="referee" v-if="[4, 5].includes(handleType)">
        <el-input style="width: 80%" v-model="transFormData.referee" placeholder="请输入推荐人"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button
        type="primary"
        @click="submit('transFormData')"
        :loading="saveLoading"
        :disabled="(!postList.length && [1, 3].includes(handleType)) || getStatus || loadingPositions"
        >确定
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
/* eslint-disable */
import { ParentList, PostOptions, changePositionFee, ChangePosition } from "../../api/member/member-detail";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    isJoined: {
      type: Array,
    },
    // ID: {
    //   type: [Number, String],
    // },
    // joinOrganizationId: {
    //   type: [Number, String],
    // },
    formData: {
      type: Object,
      required: true,
    },
    handleType: {
      type: Number,
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      loading: false,
      title: "",
      loadingPositions: false,
      parentArr: [],
      postList: [],
      positionPlaceholder: "",
      transFormData: {
        type: 0,
        id: 0,
        position_id: "",
        join_organization_id: "",
        referee: "",
        pay_status: -1,
        ids: [],
      },
      rules: {
        ids: [{ required: true, message: "请选择组织架构", trigger: "change" }],
        position_id: [{ required: true, validator: this.ids, trigger: "change" }],
      },
      PayStatusArr: [
        { name: "未缴费", id: 0 },
        { name: "已缴费", id: 1 },
        { name: "无需缴费", id: 2 },
      ],
      payTip: "",
      getStatus: false,
      payStatus: -1,
      saveLoading: false,
    };
  },
  methods: {
    updateIds(ids = []) {
      this.$nextTick(() => {
        this.transFormData.ids = JSON.parse(JSON.stringify(ids));
        this.handleSelect(this.transFormData.ids);
        console.log("update ids: ", this.transFormData.ids);
      });
    },
    // 获取架构列表
    getParentList() {
      this.loading = true;
      ParentList({ is_show_none: 0, is_all: 1, only_have_position: 1 })
        .then((res) => {
          this.parentArr = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 选择组织架构操作
    handleSelect(e) {
      console.log(e);
      const data = {};
      data.organization_id = e[e.length - 1];
      data.is_show_prepare = this.handleType === 2 ? 1 : 0;
      data.type = this.transFormData.type;
      data.position_id = this.formData.position_id;
      this.transFormData.join_organization_id = e[e.length - 1];
      this.getPositions(data);
    },
    // 获取该架构下的职务
    getPositions(data) {
      this.loadingPositions = true;
      this.positionPlaceholder = "职务加载中...";
      PostOptions(data)
        .then((res) => {
          this.postList = res.data;
          this.loadingPositions = false;
          if (this.transFormData.join_organization_id) {
            if (!res.data.length && this.handleType === 1) {
              this.positionPlaceholder = "当前职务已经是最高等级";
              return;
            }
            if (!res.data.length && this.handleType === 3) {
              this.positionPlaceholder = "当前职务已经是最低等级";
              return;
            }
            if (!res.data.length && ![1, 3].includes(this.handleType)) {
              this.positionPlaceholder = "当前组织架构没有会内职务选择";
              return;
            }
            this.positionPlaceholder = "请选择会内职务";
            return;
          } else {
            this.positionPlaceholder = "请选择组织架构";
            return;
          }
        })
        .catch((err) => {
          this.loadingPositions = false;
        });
    },
    // 选择职务时验证是否有差价
    positionSelect(val) {
      this.getStatus = true;
      const data = {
        id: this.transFormData.id,
        join_organization_id: this.transFormData.join_organization_id,
        position_id: this.transFormData.position_id,
        type: this.handleType,
      };
      if (this.handleType !== 0) {
        changePositionFee(data)
          .then((res) => {
            const { data } = res;
            this.payStatus = data.pay_status;
            this.payTip = data.text;
            this.getStatus = false;
          })
          .catch(() => {
            this.getStatus = false;
          });
      }
    },
    // 组织架构验证
    ids(rule, value, callback) {
      if (value) {
        if (this.isJoined.includes(this.transFormData.position_id)) {
          callback(new Error("已有相同的会内职务"));
        } else {
          callback();
        }
      } else {
        callback(new Error("请选择会内职务"));
      }
    },
    // 保存
    submit(formName) {
      console.log(this.transFormData);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          let data = { ...this.transFormData };
          if (this.handleType === 0) {
            data = {
              ...data,
              user_id: this.formData.user_id,
              cyc_user_id: this.formData.cyc_user_id,
            };
          }
          ChangePosition(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.updateList();
              this.saveLoading = false;
              this.openDialog = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            })
            .finally(() => {
              this.transFormData.ids = [];
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.payStatus = -1;
      this.payTip = "";
      this.$refs[formName].resetFields();
    },
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.transFormData.referee = this.formData.referee;
        this.transFormData.position_id = "";
        this.transFormData.id = this.formData.id;
        this.transFormData.type = this.handleType;
        this.positionPlaceholder = "";
        this.payStatus = -1;
        let data = {
          organization_id: this.formData.join_organization_id,
          is_show_prepare: 0,
          type: this.handleType,
          position_id: this.formData.position_id,
        };
        switch (this.handleType) {
          case 1:
            this.title = "晋升";
            this.transFormData.join_organization_id = this.formData.join_organization_id;
            this.transFormData.ids = [...this.formData.path_ids];
            console.log(this.transFormData.join_organization_id);
            this.getPositions(data);
            break;
          case 2:
            this.title = "修改组织架构 | 会内职务";
            this.transFormData.join_organization_id = "";
            this.transFormData.ids = [];
            break;
          case 3:
            this.title = "降职";
            this.transFormData.join_organization_id = this.formData.join_organization_id;
            this.transFormData.ids = [...this.formData.path_ids];
            this.getPositions(data);
            break;
          case 4:
            this.title = "修改申请信息";
            this.transFormData.join_organization_id = "";
            this.transFormData.ids = [];
            break;
          case 5:
            this.title = "修改申请信息";
            this.transFormData.join_organization_id = this.formData.apply_organization_id;
            this.transFormData.position_id = this.formData.position_id;
            break;
        }
        this.getParentList();
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 0px;
}
.el-form-item + .el-form-item {
  margin-top: 22px;
}
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 6px;
  span + span {
    margin-left: 4px;
  }
}
.dialog-vertical {
  ::v-deep.el-dialog__body {
    padding-bottom: 32px;
  }
}
</style>
