<template>
  <el-dialog
    :title="editTitle"
    :visible.sync="openDialog"
    width="580px"
    :show-close="true"
    class="dialog-vertical"
    @close="cancel('transFormData')"
  >
    <el-form
      size="medium"
      :model="transFormData"
      :rules="rules"
      label-position="right"
      label-width="100px"
      ref="transFormData"
      v-loading="loading"
      @submit.native.prevent
    >
      <el-form-item label="会员类型：" prop="status">
        <el-select
          v-model="transFormData.status"
          placeholder="请选择会员类型"
          style="width: 80%"
        >
          <el-option
            v-for="item in memberType"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="组织架构：" prop="ids" v-show="transFormData.status">
        <el-cascader
          style="width: 80%"
          v-model="transFormData.ids"
          :options="parentArr"
          :props="props"
          placeholder="请选择组织架构"
          @change="handleSelect"
          ref="myCascader"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item
        :label="transFormData.status === 1 ? '会内职务：' : '申请职务：'"
        prop="position_id"
        v-show="transFormData.status"
      >
        <el-select
          v-model="transFormData.position_id"
          :placeholder="
            positionPlaceholder ? positionPlaceholder : '请先选择组织架构'
          "
          style="width: 80%"
          :disabled="
            !transFormData.ids.length || !postList.length || loadingPosition
          "
          @change="handlePositionselect"
        >
          <el-option
            v-for="item in postList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="会费状态："
        prop="pay_status"
        v-if="transFormData.status === 1"
      >
        <el-select
          v-model="transFormData.pay_status"
          :placeholder="payStatusTip || '请先选择会内职务'"
          style="width: 80%"
          :disabled="PayStatusdisable"
        >
          <el-option
            v-for="item in PayStatusArr"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="推荐人："
        prop="referee"
        v-show="transFormData.status"
      >
        <el-input
          style="width: 80%"
          v-model="transFormData.referee"
          placeholder="请输入推荐人"
          maxlength="32"
        ></el-input>
      </el-form-item>
      <el-form-item label="入会时间：" prop="join_time">
        <el-date-picker
          v-loading="saveLoading"
          size="medium"
          v-model="transFormData.join_time"
          type="datetime"
          value-format="timestamp"
          format="yyyy-MM-dd HH:mm"
          placeholder="选择入会时间"
          time-arrow-control
          @change="setJoinTime"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button
        type="primary"
        @click="submit('transFormData')"
        :loading="saveLoading"
        :disabled="loadingPosition"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {
  ParentList,
  PostOptions,
  ChangePosition,
} from "../../api/member/member-detail";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    isJoined: {
      type: Array,
    },
    formData: {
      type: Object,
      required: true,
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      loading: false,
      loadingPosition: false,
      parentArr: [],
      postList: [],
      editTitle: "",
      positionPlaceholder: "",
      props: {
        value: "id",
        label: "name",
        checkStrictly: true,
        expandTrigger: "hover",
        // disabled: "disabled",
      },
      transFormData: {
        type: 0,
        id: 0,
        status: "",
        position_id: "",
        join_organization_id: "",
        organization_name: "",
        position_name: "",
        title_name: "",
        referee: "",
        pay_status: "",
        pay_status_text: "",
        ids: [],
        join_time: Date.parse(new Date()),
      },
      rules: {
        status: [
          { required: true, message: "请选择会员类型", trigger: "change" },
        ],
        pay_status: [
          { required: true, message: "请选择会费状态", trigger: "change" },
        ],
        join_time: [
          { required: true, message: "请选择入会时间", trigger: "change" },
        ],
        ids: [{ required: true, message: "请选择组织架构", trigger: "change" }],
        position_id: [
          { required: true, validator: this.ids, trigger: "change" },
        ],
      },
      PayStatusArr: [
        { name: "未缴费", id: 0 },
        { name: "已缴费", id: 1 },
        { name: "无需缴费", id: 2 },
      ],
      memberType: [
        { name: "正式会员", id: 1 },
        { name: "预备会员", id: 2 },
      ],
      payStatusTip: "",
      PayStatusdisable: true,
      getStatus: false,
      saveLoading: false,
      isEdit: false, //是否编辑职务
    };
  },
  methods: {
    setJoinTime(time) {
      console.log(time / 1000);
    },
    // 获取架构列表
    getParentList() {
      this.loading = true;
      ParentList({ is_show_none: 0, is_all: 1 })
        .then((res) => {
          this.parentArr = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 选择组织架构操作
    handleSelect(e, isCreated) {
      const data = {};
      data.organization_id = e[e.length - 1];
      data.is_show_prepare = 0;
      data.type = this.transFormData.type;
      data.position_id = this.formData.position_id;
      if (!isCreated) {
        const nodeData = this.$refs.myCascader.getCheckedNodes()[0];
        this.transFormData.organization_name = nodeData.data.name;
        this.transFormData.position_id = "";
      }
      this.transFormData.join_organization_id = e[e.length - 1];
      this.getPositions(data);
    },
    // 获取该架构下的职务
    getPositions(data) {
      this.loadingPosition = true;
      this.positionPlaceholder = "职务加载中...";
      PostOptions(data)
        .then((res) => {
          this.postList = res.data;
          this.loadingPosition = false;
          /* 更改会费状态 */
          if (this.transFormData.position_id) {
            this.payStatusTip = "请选择会费状态";
            const rowData = this.postList.find(
              (el) => el.id === this.transFormData.position_id
            );
            this.transFormData.pay_status = rowData.fee
              ? this.transFormData.pay_status
              : 2;
            this.PayStatusdisable =
              this.transFormData.pay_status === 2 ? true : false;
            this.PayStatusArr = rowData.fee
              ? [
                  { name: "未缴费", id: 0 },
                  { name: "已缴费", id: 1 },
                ]
              : [{ name: "无需缴费", id: 2 }];
          } else {
            this.transFormData.pay_status = "";
            this.PayStatusdisable = true;
            this.payStatusTip = "请先选择会内职务";
          }
          if (this.transFormData.join_organization_id) {
            if (res.data.length) {
              this.positionPlaceholder = "请选择会内职务";
            } else {
              this.positionPlaceholder = "当前组织架构没有会内职务选择";
            }
          } else {
            this.positionPlaceholder = "请先选择组织架构";
          }
        })
        .catch((err) => {
          this.loadingPosition = false;
        });
    },
    // 选择职务
    handlePositionselect(val) {
      this.transFormData.position_name = this.postList.find(
        (el) => el.id === val
      ).name;
      if (val) {
        this.payStatusTip = "请选择会费状态";
        const rowData = this.postList.find((el) => el.id === val);
        this.transFormData.pay_status = rowData.fee ? "" : 2;
        this.PayStatusdisable =
          this.transFormData.pay_status === 2 ? true : false;
        this.PayStatusArr = rowData.fee
          ? [
              { name: "未缴费", id: 0 },
              { name: "已缴费", id: 1 },
            ]
          : [{ name: "无需缴费", id: 2 }];
      } else {
        this.transFormData.pay_status = "";
        this.PayStatusdisable = true;
        this.payStatusTip = "请先选择会内职务";
      }
    },
    // 组织架构验证
    ids(rule, value, callback) {
      if (value) {
        if (this.isJoined.includes(this.transFormData.position_id)) {
          callback(new Error("已有相同的职务"));
        } else {
          callback();
        }
      } else {
        callback(new Error("请选择会内职务"));
      }
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.transFormData.join_time = Math.floor(
            this.transFormData.join_time / 1000
          );
          console.log(this.transFormData.join_time, 999);
          if (
            this.$route.name === "AddMember" ||
            this.$route.name === "AddUnit"
          ) {
            const addData = { ...this.transFormData };
            addData.title_name =
              addData.status === 1
                ? addData.organization_name + " | " + addData.position_name
                : addData.organization_name + " | 预备会员";
            if (addData.status === 2) {
              const positionData = this.postList.find(
                (el) => el.id === addData.position_id
              );
              addData.pay_status = positionData.fee ? 0 : 2;
            }
            addData.pay_status_text = this.PayStatusArr.find(
              (el) => el.id === addData.pay_status
            ).name;
            this.$emit("change", addData);
            console.log(addData);
            this.$refs[formName].resetFields();
            this.openDialog = false;
          } else {
            this.saveLoading = true;
            const data = {
              ...this.transFormData,
              user_id: this.formData.user_id,
              cyc_user_id: this.formData.cyc_user_id,
            };
            if (data.status === 2) {
              const positionData = this.postList.find(
                (el) => el.id === data.position_id
              );
              data.pay_status = positionData.fee ? 0 : 2;
            }
            ChangePosition(data)
              .then((res) => {
                this.$message.success(res.msg);
                this.updateList();
                this.saveLoading = false;
                this.$refs[formName].resetFields();
                this.openDialog = false;
              })
              .catch((err) => {
                this.saveLoading = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.$refs[formName].resetFields();
    },
  },
  watch: {
    openDialog(val) {
      if (val) {
        if (this.$route.name === "AddMember" && this.formData.status) {
          this.isEdit = true;
          this.transFormData = { ...this.formData };
          this.transFormData.join_time = this.transFormData.join_time * 1000;
          this.handleSelect(this.formData.ids, 1);
          this.PayStatusdisable = this.formData.pay_status === 2;
          this.editTitle = "编辑职务";
        } else {
          this.isEdit = false;
          this.editTitle = "添加职务";
        }
        this.getParentList();
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 0px;
}
.el-form-item + .el-form-item {
  margin-top: 22px;
}
.el-cascader {
}
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 6px;
  span + span {
    margin-left: 4px;
  }
}
</style>
