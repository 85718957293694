<template>
  <div class="confirm-content">
    <el-table :data="classData" class="thead-light" stripe style="width: 100%">
      <!-- 序号 -->
      <el-table-column
        width="60"
        type="index"
        :index="
          (index) => {
            return index + 1;
          }
        "
        label="序号"
      ></el-table-column>

      <template>
        <el-table-column label="班级" prop="class_name"></el-table-column>
        <!-- <el-table-column label="ID" prop="class_id"></el-table-column> -->
        <el-table-column width="114" prop="teacher" label="班主任"></el-table-column>
        <el-table-column width="100" label="设为展示" how-overflow-tooltip>
          <template slot-scope="scope">
            <el-radio :label="scope.$index" v-model="OnShow" @change="() => {}"
              ><span>{{ " " }}</span></el-radio
            >
          </template>
        </el-table-column>
      </template>

      <!-- 操作 -->
      <el-table-column label="操作" :width="120" fixed="right">
        <template slot-scope="scope">
          <el-button-group>
            <el-button type="text" size="small" @click="EditRow(scope.$index)">编辑</el-button>
            <el-button type="text" size="small" @click="DelRow(scope.$index)">移除</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-button class="bottom-btn opacity-btn" @click="isShowAdd = true">+ 添加班级</el-button>
    <!-- 添加班级 -->
    <AddClass
      v-model="isShowAdd"
      :formItem="form"
      :ClassForm="ClassForm"
      :updateList="reflashList"
      :selection="selection"
      not-post
      @comfirmData="comfirm"
      @editData="editData"
      ref="ac"
    ></AddClass>
  </div>
</template>

<script>
import AddClass from "./AddClass.vue";
import { searchClass, myClasses } from "../../api/member/class-message";
import { ClassMembers } from "../../api/school-class-list";

export default {
  props: {},
  components: { AddClass },
  data() {
    return {
      placeholder: "--",
      classData: [],
      isShowAdd: false,
      form: {}, // id
      ClassForm: [], // 班级列表
      selection: {
        periodList: [],
        campusList: [],
        yearList: [],
        ClassList: [],
      },
      OnShow: 0,
    };
  },
  watch: {
    OnShow(n) {
      console.log("onShow changed: ", n);
      this.updateShowChange(n);
    },
  },
  methods: {
    DelRow(i) {
      this.classData.splice(i, 1);
      if (this.OnShow === i) {
        this.OnShow = 0;
      }
    },
    EditRow(i) {
      this.$refs.ac.edit(this.classData[i], i, this.classData[i].form);
      this.isShowAdd = true;
    },
    editData(res, index, originalData) {
      // check dup
      for (let i = 0; i < this.classData.length; i++) {
        if (this.classData[i].class_id === res.class_id && originalData.class_id !== res.class_id) {
          console.log("comfirm dup. class_id: ", res.class_id);
          this.$message.error("不能有重复的班级");
          return;
        }
      }
      this.classData[index] = res;
    },
    getList(data) {
      return myClasses(data);
    },
    updateShowChange(index) {
      for (let i = 0; i < this.classData.length; i++) {
        if (index === i) {
          this.classData[i].on_show = 1;
        } else {
          this.classData[i].on_show = 0;
        }
      }
      this.$emit("updateClassData", this.classData);
    },
    comfirm(res) {
      if (res.class_id === "" || res.class_id === null || res.class_id === undefined) {
        this.$message.error("请添加正确的班级");
        return;
      }
      console.log("comfirm: ", res);
      // check dup
      for (let i = 0; i < this.classData.length; i++) {
        if (this.classData[i].class_id === res.class_id) {
          console.log("comfirm dup. class_id: ", res.class_id);
          this.$message.error("不能有重复的班级");
          return;
        }
      }
      for (let i = 0; i < this.classData.length; i++) {
        if (this.OnShow === i) {
          res.on_show = 1;
        } else {
          res.on_show = 0;
        }
      }
      this.classData.push(res);
      this.$emit("updateClassData", this.classData);
    },
    reflashList() {
      console.log("-----selection", this.selection);
    },
    // 获取班级列表
    getClassMembers() {
      ClassMembers()
        .then((res) => {
          this.ClassForm = res.data.data;
        })
        .catch((err) => console.log(err));
    },
    getSearch() {
      searchClass()
        .then((res) => {
          this.selection.periodList = res.data.period;
          this.selection.campusList = res.data.campus;
          this.selection.yearList = res.data.entrance_year;
          this.selection.ClassList = res.data.class;
        })
        .catch((err) => console.log(err));
    },
  },
  created() {
    this.getSearch();
    this.getClassMembers();
  },
};
</script>

<style lang="scss" scope>
.tool-tip {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.bottom-btn {
  width: 100%;
  margin-top: 8px;
  border: 1px dashed rgba(217, 217, 217, 1) !important;
  font-size: 16px;
  padding: 8px 0 !important;
}

.bottom-btn:hover {
  border: 1px dashed #3576ff;
  padding: 8px 0 !important;
}

.el-button-group {
  display: flex;
  align-items: center;
}
</style>
