import api from '@/base/utils/request';
import fetchFile from "@/base/utils/download";

export const getAddressJson = data => {
  return api({
    url: '/admin/admin/data/getAddressJson',
    method: 'post',
    data
  })
}
