import api from "@/base/utils/request";

// 审核通过
export const Checked = data => {
  return api({
    url: "/admin/cyc/user/check",
    method: "post",
    data
  });
};

//审核不通过
export const NoCheck = data => {
  return api({
    url: "/admin/cyc/user/nocheck",
    method: "post",
    data
  });
};