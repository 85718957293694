<template>
  <el-dialog
    title="修改入会时间"
    :visible.sync="openDialog"
    width="450px"
    :show-close="false"
    append-to-body
    class="dialog-vertical"
  >
    <el-form size="medium" :model="form" ref="form" label-width="100px">
      <el-form-item label="日期：" required>
        <el-date-picker
          v-model="form.time"
          type="datetime"
          placeholder="选择日期时间"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { changeJoinTime } from '../../api/member/member-list';
export default {
  props: ['openDialog', 'user_position_id'],
  data() {
    return {
      form: {
        time: '',
      },
    };
  },
  methods: {
    // 确认
    submit() {
      let join_time = +new Date(this.form.time / 1000);
      changeJoinTime({ id: this.user_position_id, join_time: join_time }).then(
        (res) => {
          console.log('res', res);
          if (res.msg == '保存成功') {
            this.$message({
              message: '修改成功',
              type: 'success',
            });
            this.$emit('hideDialog',true)
          }
        }
      );
      this.$emit('hideDialog', false);
    },

    // 取消
    cancel() {
      this.$emit('hideDialog', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  p {
    height: 24px;
    color: #c7c7c7;
  }
}
</style>
