import api from '@/base/utils/request'

export const getRegionSetting = (data) => {
  return api({ url: '/admin/cyc/settings/getRegionSetting', method: 'post', data })
}

export const setRegionSetting = (data) => {
  return api({ url: '/admin/cyc/settings/setRegionSetting', method: 'post', data })
}

