<!--
 * @Author: dongjia
 * @Date: 2021-10-19 15:11:55
 * @LastEditTime: 2021-10-28 16:29:58
 * @LastEditors: aleaner
 * @Description: 关联企业弹窗
 * @FilePath: \organization-admin\components\Member\RelateCompanyDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog :title="dialogTitle" :visible.sync="openDialog" width="800px" :show-close="true"
    class="dialog-vertical">
    <div class="dialog-content">
      <div class="data-list-header">
        <div class="header-top">
          <div class="header-btn-group">
            <el-button class="item-btn" type="primary" size="small" @click="handleAdd">新建</el-button>
            <el-button class="item-btn" size="small" @click="handleAdmin">管理</el-button>
            <el-button class="item-btn" size="small" @click="refreshList">刷新</el-button>
          </div>
          <div class="header-filter">
            <el-input style="width: 220px" v-model="filterForm.keyword" :placeholder="`搜索${dialogTitle}名称`"
              @change="handleFilter">
              <i class="el-icon-search el-input__icon" slot="suffix"></i>
            </el-input>
          </div>
        </div>
        <div class="header-bottom">
          <div class="header-filter"></div>
        </div>
        <el-table :data="dataList" style="width: 100%;cursor: pointer;margin-top: 10px;" :height="250"
          ref="multipleTable" @row-click="handleCurrentChange" v-loading="loading" v-loadmore="LoadMore">
          <!-- 单选 -->
          <el-table-column label="操作" fixed="left" width="70" :key="Math.random()">
            <template slot-scope="scope">
              <el-radio :label="scope.row.id" v-model="company_id"><span></span></el-radio>
            </template>
          </el-table-column>
          <template v-for="(th, index) in thead">
            <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
              :min-width="th.minWidth || ''" :key="index">
              <template slot-scope="scope">
                <div class="status-text" v-if="th.type === 'status_text'">
                  <div class="status-dot" :style="{
                    backgroundColor: scope.row[th.status_text_field]
                  }"></div>
                  <div>{{scope.row[th.prop] | placeholder}}</div>
                </div>
                <span v-else>{{scope.row[th.prop] | placeholder}}</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
      <InputCompanyPositionDialog v-model="openInputPositionDialog" :relateData="relateData"
        :complete="cancel" />
    </div>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { dataList } from "../../api/member/member-detail";
import InputCompanyPositionDialog from "./InputCompanyPositionDialog.vue";
import debounce from 'lodash/debounce'
export default {
  name: "dataSelector",
  components: { InputCompanyPositionDialog },
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    dataType: {
      type: String,
      default: "st-company-list",
    },
    userId: {
      type: [Number, String],
    },
  },
  data() {
    return {
      loading: false, //加载
      dialogTitle: "专题",
      handleAddRouterName: "addBrand",
      handleAdminRouterName: "CycBrandList",
      thead: [],
      dataList: [],
      //筛选对象
      filterForm: {
        category_id: -1,
        keyword: "", //关键词
        page_size: 10, //每页多少条数据
        page: 1,
        component: "",
      },
      getable: true, //是否可以请求列表
      // 联级选择配置
      props: {
        value: "id",
        label: "name",
        expandTrigger: "hover",
        children: "position",
      },
      categoryList: [],
      selectArr: [], //勾选的数组
      company_id: "",
      relateData: {
        user_id: "",
        company_id: "",
      }, // 关联企业参数数据
      openInputPositionDialog: false,
    };
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.getDataList(this.filterForm);
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
  created() {
    this.filterForm.component = this.dataType;
  },
  methods: {
    // 获取数据列表
    getDataList(pageData) {
      if (this.getable) {
        this.loading = true;
        dataList(pageData)
          .then((res) => {
            try {
              const { data } = res;
              this.handleAddRouterName = data.button_new;
              this.handleAdminRouterName = data.button_manage;
              this.dialogTitle = data.name;
              this.thead = data.table;
              if (pageData.page === 1) {
                this.dataList = data.list.data;
              } else {
                this.dataList = [...this.dataList, ...data.list.data];
              }
              if (data.list.data.length < this.filterForm.page_size) {
                this.getable = false;
              }
              this.filterForm.page++;
            } catch (e) {
              console.log(e);
            }
            this.loading = false;
            this.$nextTick(() => {
              this.$refs.multipleTable.doLayout();
            });
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 筛选操作
    handleFilter(e, key) {
      const fliterData = { ...this.filterForm };
      fliterData.page = key ? fliterData.page : 1;
      this.getable = true;
      this.getDataList(fliterData);
    },
    // 点击数据项操作
    handleCurrentChange(val) {
      this.dataList.forEach((element) => {
        if (val.id === element.id) {
          this.company_id = element.id;
        }
      });
    },
    //触底刷新
    LoadMore: debounce(function () {
      if (this.getable && this.dataList.length >= 10) {
        this.handleFilter(this.filterForm, "Load");
      }
    }, 200),
    // 刷新
    refreshList() {
      this.getable = true;
      this.handleFilter({ ...this.filterForm, page: 1 });
    },
    // 点击新增
    handleAdd() {
      let routerData = this.$router.resolve({
        name: this.handleAddRouterName,
        params: { id: 0, type: "add" },
      });
      window.open(routerData.href, "_blank");
    },
    // 点击管理
    handleAdmin() {
      let routerData = this.$router.resolve({
        name: this.handleAdminRouterName,
      });
      window.open(routerData.href, "_blank");
    },
    // 确定
    submit() {
      if (!this.company_id) {
        this.$message.info("请选择数据项");
      } else {
        this.relateData = {
          user_id: this.userId,
          company_id: this.company_id,
        };
        this.openInputPositionDialog = true;
        // this.$emit("change", this.selectArr);
        // this.openDialog = false;
      }
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.data-list-header {
  .header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-btn-group {
    }
    .header-filter + .header-filter {
      margin-left: 10px;
    }
  }
}
.data-list {
  margin-top: 20px;
}
.status-text {
  display: flex;
  align-items: center;
  .status-dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
  }
}
</style>
